import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import useGet from "api/useGet";
import { useNavigate } from "react-router-dom";
const { setDateTime } = require("js/function");

function NewsBox(data){
    return (
        <div className="newsListBox">
            {data.children}
        </div>
    );
}

function NewsItem(data){
    const navigate = useNavigate();

    return (
        <div className="newsItem">
            <div className="newsImgBox" style={{backgroundImage:`url(${data?.data?.main_image_url})`}}></div>
            <div className="newsTextBox">
                <div className="newsInfo">
                    <div className="newsInfoBox">
                        <p className="newsType">{data?.data?.category_name}</p>
                        <span className="newsListDot"></span>
                        <p className="newsDate">{setDateTime(data?.data?.news_date,"yymmdd")}</p>
                    </div>
                    <h1 className="newsTitle">{data?.data?.title}</h1>
                </div>
                <button type="button" onClick={()=>{navigate(`/news/detail/${data?.data?.id}`)}} className="newsListBtn">자세히 알아보기</button>
            </div>
        </div>
    );
}

function NewsContents(data){
    return (
        <div className="newsContents">
            {data.children}
        </div>
    );
}

function NewsDetailSubList(data){
    const navigate = useNavigate();

    return (
        <button type="button" onClick={()=>{navigate(`/news/detail/${data.id}`)}} className="newsDetailSubList">
            <div className="newsDetailSubList_type">
                {data.type == "prev" ? <><img src="/assets/images/basic/board_prev.svg"/> 이전글</> : <><img src="/assets/images/basic/board_next.svg"/> 다음글</>}
            </div>
            <div className="newsDetailSubList_infoBox">
                <div className="newsDetailSubList_info">
                    <p className="newsDetailSubList_cate">{data.cate}</p>
                    <p className="newsDetailSubList_date">{data.date}</p>
                </div>
                <h1 className="newsDetailSubList_title">{data.title}</h1>
            </div>
        </button>
    );
}

function NewsSlide(data){
    const navigate = useNavigate();
    const newsApi = useGet({url:`/news`});
    const newsData = newsApi?.data?.news;

    return (
        <div className="newsSlide_section">
            <div className="newsSlide_area">
                <h1 className="newsSlide_title">News</h1>
                <span></span>
                <div className="newsSlide_slideBox">
                    <Swiper
                        spaceBetween={15}
                        slidesPerView={1}
                        autoplay={{delay: 5000,disableOnInteraction:false}}
                        loop={true}
                        direction="vertical"
                        modules={[Autoplay]}
                    >
                        {newsData?.map((newsItem, i) => (
                            <SwiperSlide key={i}>
                                <button type="button" onClick={()=>{navigate(`/news/detail/${newsItem.id}`)}} className="newsSlide_item">{newsItem.title}</button>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export {NewsBox, NewsItem, NewsContents, NewsDetailSubList, NewsSlide}