import { PageSizing } from "component/basic/pageSizing";
import { FullBanner } from "component/elements/fullBanner";
import { Header } from "component/elements/header";
import { moveScroll } from "js/function";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { BottomBtns, MainFooter, MainTitle, Role_itemBox, TitleDot } from "component/elements/items";

const Main = (props) => {
    const navigate = useNavigate();
    
    const [bgType,setBgType] =  useState("");
    const [colorType,setColorType] =  useState("");
    const [subBannerEv,setSubBannerEv] =  useState("");
    const [brandEv,setBrandEv] =  useState("");
    const [roleEv,setRoleEv] =  useState("");

    const [blindType,setBlindType] =  useState(true);
    
    const brandData = ["img_0","img_1","img_2","img_3","img_4","img_5","img_6","img_7","img_8","img_9","img_10","img_11"]

    function handleScroll(){
        let scrollTop = window.scrollY;
        let roleItemTop = document.querySelectorAll(".role_section")[0].offsetTop;
        let contentsTop = document.querySelectorAll(".mainContents")[0].offsetTop;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight / 2;
        let windowH = window.innerHeight;

        document.querySelectorAll(".mainBanner>img")[0].style.transform = `scale(${scrollTop / 600 >= 1 ? scrollTop / 600 >= 2.5 ? 2.5 : scrollTop / 600 : 1})`;

        document.querySelectorAll(".mainBannerTitle")[0].style.opacity = 1 - scrollTop/1000 >= 1 ? 1 : 1 - scrollTop/1000 <= 0 ? 0 : 1 - scrollTop/1000;

        document.querySelectorAll(".mainBanner>img")[0].style.opacity = 1 - (scrollTop - 500) / 1000 >= 1 ? 1 : 1 - (scrollTop - 500) / 1000 <= 0 ? 0 : 1 - (scrollTop - 500) / 1000;

        document.querySelectorAll(".mainSubBanner")[0].style.opacity = (scrollTop - 1000) / 1000 >= 1 ? 1 : (scrollTop - 1000) / 1000 <= 0 ? 0 : (scrollTop - 1000) / 1000;

        if((scrollTop - 1000) / 1000 >= 0.75 && scrollTop + headerSize < contentsTop){
            setColorType("wColor")
        }else{
            setColorType("")
        }

        document.querySelectorAll(".mainSubBanner_title")[0].style.opacity = (scrollTop - 2000) / 1000 >= 1 ? 1 : (scrollTop - 2000) / 1000 <= 0 ? 0 : (scrollTop - 2000) / 1000;
        document.querySelectorAll(".mainSubBanner_title")[0].style.transform = `scale(${1.8 - (scrollTop - 2000) / 1200 >= 1.8 ? 1.8 : 1.8 - (scrollTop - 2000) / 1200 <= 1 ? 1 : 1.8 - (scrollTop - 2000) / 1200})`;

        document.querySelectorAll(".mainSubBanner_title")[1].style.opacity = (scrollTop - 3000) / 1000 >= 1 ? 1 : (scrollTop - 3000) / 1000 <= 0 ? 0 : (scrollTop - 3000) / 1000;
        document.querySelectorAll(".mainSubBanner_title")[1].style.transform = `scale(${1.8 - (scrollTop - 3000) / 1200 >= 1.8 ? 1.8 : 1.8 - (scrollTop - 3000) / 1200 <= 1 ? 1 : 1.8 - (scrollTop - 3000) / 1200})`;

        document.querySelectorAll(".mainSubBanner_title")[2].style.opacity = (scrollTop - 4000) / 1000 >= 1 ? 1 : (scrollTop - 4000) / 1000 <= 0 ? 0 : (scrollTop - 4000) / 1000;
        document.querySelectorAll(".mainSubBanner_title")[2].style.transform = `scale(${1.8 - (scrollTop - 4000) / 1200 >= 1.8 ? 1.8 : 1.8 - (scrollTop - 4000) / 1200 <= 1 ? 1 : 1.8 - (scrollTop - 4000) / 1200})`;

        document.querySelectorAll(".mainSubBannerBg")[0].style.transform = `translate(-50%,-50%) scale(${(scrollTop - 4500) / 800 >= 1 ? (scrollTop - 4500) / 800 >= 1.3 ? 1.3 : (scrollTop - 4500) / 800 : 1})`;
        
        if(scrollTop + headerSize >= contentsTop){
            setBgType("sub")
        }else{
            setBgType("")
        }

        if(scrollTop + headerSize >= contentsTop - windowH * 0.5){
            setBlindType(false)
        }else{
            setBlindType(true)
        }

        if(scrollTop + headerSize >= contentsTop - windowH * 0.5){
            setBrandEv("active")
        }

        if(scrollTop + headerSize >= roleItemTop + contentsTop - windowH * 0.5){
            setRoleEv("active")
        }
    };

    useEffect(() => {
        handleScroll();
        const scrollEv = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(scrollEv);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <PageSizing addClass="mainPage">
            <Header type="home" colorType={colorType} bgType={bgType}/>
            <FullBanner height="6000">
                <div className="mainBanner">
                    <div className="mainBannerBg"></div>
                    <img src="/assets/images/img/main_bg_icon.svg"/>
                    <h1 className="mainBannerTitle">혁신은 강력한<br/>보안성 연구로 부터 나옵니다.</h1>
                </div>
                <div className="mainSubBanner">
                    <h2 className="mainSubBanner_title">스팟을 찾는 것으로부터 연구가 시작되고, </h2>
                    <h2 className="mainSubBanner_title">강력한 기술, 합법적으로 엑세스하는 우리는</h2>
                    <h2 className="mainSubBanner_title">윤리적 해킹을 지향하는<br/>더스팟 입니다<span>.</span></h2>
                    <div className="mainSubBannerBg"/>
                </div>
                <div className="btn_bannerBottomItem" onClick={()=>{moveScroll(".mainContents",0)}}><img src="/assets/images/basic/main_down.svg"/></div>
            </FullBanner>
            <div className="mainContents">
                <div className={`brandSlide_section ${brandEv}`}>
                    <h1 className="brandSlide_title">법 집행과 정의구현이 필요한,<br/>모든 곳에서 더스팟은 여러분과 함께합니다.</h1>
                    <div className="brandSlide_area">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={7}
                            loopedSlides={3}
                            modules={[Autoplay]}
                            autoplay={{delay: 5000,disableOnInteraction:false}}
                            loop={true}
                            centeredSlides={true}
                            breakpoints={{
                                1700:{
                                    slidesPerView:7      
                                },
                                960:{
                                    slidesPerView:5     
                                },
                                600:{
                                    slidesPerView:3   
                                },
                                100:{
                                    slidesPerView:2
                                },
                            }}
                        >
                            {brandData?.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <div className="brandSlideItem">
                                        <img src={`/assets/images/brand/${item}_off.svg`} className="brandSlideItem_off"/>
                                        <img src={`/assets/images/brand/${item}_on.svg`} className="brandSlideItem_on"/>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className={`role_section ${roleEv}`}>
                    <div className="role_area">
                        <div className="role_titleArea">
                            <MainTitle title="더스팟의 <br/>사회적 역할"/>
                            <TitleDot/>
                        </div>
                        <div className="role_itemArea">
                            <Role_itemBox type="0"/>
                            <Role_itemBox type="1"/>
                            <Role_itemBox type="2"/>
                            <Role_itemBox type="3"/>
                        </div>
                        <div className="role_slideBox">
                            <Swiper
                                spaceBetween={16}
                                slidesPerView={1}
                                loopedSlides={2}
                                modules={[Pagination,Autoplay]}
                                autoplay={{delay: 5000,disableOnInteraction:false}}
                                loop={true}
                                pagination={{
                                    el: '.custom_pagination'
                                }}
                            >
                                <SwiperSlide>
                                    <Role_itemBox type="0"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Role_itemBox type="1"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Role_itemBox type="2"/>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Role_itemBox type="3"/>
                                </SwiperSlide>
                                <div className="custom_pagination"></div>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            <MainFooter/>
            <BottomBtns blindType={blindType}/>
        </PageSizing>
    );
};

export default Main;