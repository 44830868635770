import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Header = (data) => {
    let navigate = useNavigate();
    const [menuOpen,setMenuOpen] = useState(false);

    return (
        <>
        <div className={`header ${data.colorType && !menuOpen ? data.colorType : ""} ${data.bgType == "sub" ? "subHeader" : ""}`}>
            <div className="headerSection">
                <button type="button" onClick={()=>{navigate("/main")}} className="btn_logo"><img src={data.colorType == "wColor" && !menuOpen ? "/assets/images/img/logo_w.svg" : "/assets/images/img/logo.svg"}/></button>
                <div className="pcHeader_menuBox">
                    <button type="button" onClick={()=>{navigate("/main")}} className={`pcHeader_menu ${data.type == "home" ? "active" : ""}`}>회사소개</button>
                    <button type="button" onClick={()=>{navigate("/about")}} className={`pcHeader_menu ${data.type == "about" ? "active" : ""}`}>연구분야</button>
                    <button type="button" onClick={()=>{navigate("/news")}} className={`pcHeader_menu ${data.type == "news" ? "active" : ""}`}>최신소식</button>
                    <a href="https://medium.com/thespotinc" target="_blank" className={`pcHeader_menu ${data.type == "blog" ? "active" : ""}`}>블로그</a>
                    <a href="https://thespot.career.greetinghr.com" target="_blank" className={`pcHeader_menu ${data.type == "careers" ? "active" : ""}`}>채용</a>
                </div>
                <div className="btn_mMenuBox">
                    <button type="button" onClick={()=>{setMenuOpen(!menuOpen)}} className={`btn_mMenu ${menuOpen ? "active" : ""}`}>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
        <div className={`mHeader_menuBox ${menuOpen ? "active" : ""}`}>
            <button type="button" onClick={()=>{navigate("/main")}} className={`mHeader_menu ${data.type == "home" ? "active" : ""}`}>회사소개</button>
            <button type="button" onClick={()=>{navigate("/about")}} className={`mHeader_menu ${data.type == "about" ? "active" : ""}`}>연구분야</button>
            <button type="button" onClick={()=>{navigate("/news")}} className={`mHeader_menu ${data.type == "news" ? "active" : ""}`}>최신소식</button>
            <a href="https://medium.com/thespotinc" target="_blank" className={`mHeader_menu ${data.type == "blog" ? "active" : ""}`}>블로그</a>
            <a href="https://thespot.career.greetinghr.com" target="_blank" className={`mHeader_menu ${data.type == "careers" ? "active" : ""}`}>채용</a>
        </div>
        </>
    );
}

export {Header};