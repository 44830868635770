import { useEffect } from "react";
import { useState } from "react";

//category item
function CategoryBox(data){
    const [value,setValue] = useState(data.val);

    useEffect(() => {
        setValue(data.val);
    }, [data.val]);

    return (
        <div className={`categoryBox ${data.addClass ? data.addClass : ""}`}>
            {data.allChk ? 
                <button type="button" onClick={() => data.func(data.allChk.id)} className={`categoryItem ${data.allChk.id == value ? "active" : ""}`}>{data.allChk.name}</button>
            :""}
            {data.data && data.data?.map((item, i) => (
                <button type="button" key={i} onClick={() => data.func(data.dataType == "name" ? item[data.keyName] : item.id)} className={`categoryItem ${data.dataType == "name" ? item[data.keyName] == value ? "active" : "" : item.id == value ? "active" : ""}`}>{item[data.keyName]}</button>
            ))}
        </div>
    );
}

export {CategoryBox};