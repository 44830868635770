import { DropMenu } from "./items";

//footer
function Footer(data){
    return (
        <div className="footer">
            <div className="footerSection">
                <div className="footerArea">
                    <h2 className="footer_title">더스팟 기술 블로그</h2>
                    <div className="footer_linkBox">
                        <a href="https://medium.com/thespotinc/tech-article/home" target="_blank" className="footer_link">
                            <img src="/assets/images/basic/footer_icon_0.svg" className="footer_link_off"/>
                            <img src="/assets/images/basic/footer_icon_on_0.svg" className="footer_link_on"/>
                            포렌식 기술 스크랩
                            <img src="/assets/images/basic/footer_move_off.svg" className="footer_link_off footer_link_icon"/>
                            <img src="/assets/images/basic/footer_move_on.svg" className="footer_link_on footer_link_icon"/>
                        </a>
                        <a href="https://medium.com/thespotinc/android-cve/home" target="_blank" className="footer_link">
                            <img src="/assets/images/basic/footer_icon_1.svg" className="footer_link_off"/>
                            <img src="/assets/images/basic/footer_icon_on_1.svg" className="footer_link_on"/>
                            안드로이드 취약점 이슈
                            <img src="/assets/images/basic/footer_move_off.svg" className="footer_link_off footer_link_icon"/>
                            <img src="/assets/images/basic/footer_move_on.svg" className="footer_link_on footer_link_icon"/>
                        </a>
                        <a href="https://medium.com/thespotinc/vendor-update/home" target="_blank" className="footer_link">
                            <img src="/assets/images/basic/footer_icon_2.svg" className="footer_link_off"/>
                            <img src="/assets/images/basic/footer_icon_on_2.svg" className="footer_link_on"/>
                            벤더사 별 업데이트 내역
                            <img src="/assets/images/basic/footer_move_off.svg" className="footer_link_off footer_link_icon"/>
                            <img src="/assets/images/basic/footer_move_on.svg" className="footer_link_on footer_link_icon"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="footerSection">
                <div className="footerArea footerAreaFlexBox">
                    <div className="footerInfo_box">
                        <img src="/assets/images/img/footer_logo.svg"/>
                        <div className="footerText">
                        사업자 등록번호 : 848-81-01415<br/>
                        대표 : 나성훈<br/>
                        통신판매업 신고번호 : 2021-서울성북-1733<a href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=8488101415" target="_blank">사업자정보확인</a><br/>
                        본사 : 서울틀별시 성북구 안암로 145, 고려대학교 R&D센터 640A<br/>
                        연구소 : 서울특별시 성북구 고려대로 26길 49, 태종빌딩 301호 (주)더스팟<br/>
                        이메일 : contact@thespot.kr
                        </div>
                        <p className="footerCaption">Copyright © the spot. All rights reserved.</p>
                    </div>
                    <div className="dropMenu_box">
                        <DropMenu
                            btnText="FAMILY SITE"
                            listData={[{link:"https://www.gmdsoft.com/",name:"GMDSOFT"}]}                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export {Footer}