//날짜 표기형식 세팅
function setDateTime(item,type,unit){
	let setDate = "";
	if(item){
		let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
		let date = new Date(basicDate),
			yy,
			mm,
			dd,
			th,
			tm,
			ts;

		yy = date.getFullYear();
		mm = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
		dd = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
		th = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
		tm = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
		ts = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();

		if(type == "yymmdd"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일";
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd;
			}else{
				setDate = yy + "." + mm + "." + dd;
			}
		}else if(type == "yymmddhhtt"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm;
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm;
			}
		}else if(type == "yymmddhhttss"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm + ":" + ts;
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm + ":" + ts;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
			}
		}else if(type == "hhttss"){
			setDate = th + ":" + tm + ":" + ts;
		}else if(type == "hhtt"){
			setDate = th + ":" + tm;
		}else{
			setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
		}
	}
	return setDate;
}

//날짜 요일 세팅
function setDateWeek(item){
    const weekArr = ["일","월","화","수","목","금","토"];
	if(item){
		let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
		let date = new Date(basicDate)
		let setWeek = weekArr[date.getDay()];

		return setWeek;
	}else{
		return "";
	}
}

//url 파라미터
function getParam(sname) {
	var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
	var sval = "";
	params = params.split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

//스크롤 하단 체크
function bottomChk(){
    let scrollTop = document.documentElement.scrollTop,
		innerHeight = window.innerHeight,
		scrollHeight = document.querySelectorAll("body")[0].scrollHeight;

    if (Math.ceil(scrollTop + innerHeight) + 1 >= scrollHeight) {
        return true;
    } else {
        return false;
    }
}

//토글 슬라이드
function slideUp(target, duration){
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.boxSizing = "border-box";
	target.style.height = target.offsetHeight + "px";
	target.style.overflow = "hidden";
	window.setTimeout(() => {
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
	}, 10);
	window.setTimeout(() => {
		target.style.display = "none";
		target.style.removeProperty("height");
		target.style.removeProperty("padding-top");
		target.style.removeProperty("padding-bottom");
		target.style.removeProperty("margin-top");
		target.style.removeProperty("margin-bottom");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideDown(target, duration, type){
	target.style.removeProperty("display");
	let display = window.getComputedStyle(target).display;

	if (display === "none") display = type == "flex" ? "flex" : "block";

	target.style.display = display;
	let height = target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	target.style.height = target.offsetHeight + "px";
	target.style.boxSizing = "border-box";
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.height = height + "px";
	target.style.removeProperty("padding-top");
	target.style.removeProperty("padding-bottom");
	target.style.removeProperty("margin-top");
	target.style.removeProperty("margin-bottom");
	window.setTimeout(() => {
		target.style.removeProperty("height");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideToggle(target, type){
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, 300, type);
  } else {
    return slideUp(target, 300);
  }
}

//스크롤 이동
function moveScroll(item,addTop){
	let scrollItem = item == "top" ? 0 : document.querySelectorAll(item)[0].offsetTop;
	window.scrollTo({left: 0, top: addTop ? scrollItem - addTop : scrollItem, behavior: "smooth"});
}

export {bottomChk, slideUp, slideDown, slideToggle, getParam, setDateTime, moveScroll, setDateWeek};