import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { publicRoutes } from "./routes";
import ScrollToTop from "component/basic/scrollTopSet";

const Router = () => {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                {/* 기본접근 */}
                {publicRoutes.map(
                    ({ exact, path, component }) => (
                        <Route
                            key={`public-route-${path}`}
                            exact={exact}
                            path={path}
                            element={component}
                        />
                    )
                )}
            </Routes>
        </BrowserRouter>
    );
};

export default Router;