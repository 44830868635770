import { PageSizing } from "component/basic/pageSizing";
import { Header } from "component/elements/header";
import { setDateTime } from "js/function";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BottomBtns, SubPage } from "component/elements/items";
import useGet from "api/useGet";
import { NewsContents, NewsDetailSubList, NewsSlide } from "component/elements/news";
import { Footer } from "component/elements/footer";
import { SectionBtn } from "component/basic/btns";

const NewsDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const newsApi = useGet({url:`/news/detail?id=${id}`});
    const newsData = newsApi?.data?.news;
    const newsNextData = newsApi?.data?.next;
    const newsPrevData = newsApi?.data?.prev;

    useEffect(() => {
        
    }, []);

    return (
        <>
        <PageSizing>
            <Header type="news" bgType="sub"/>
            <SubPage>
                <NewsSlide/>
                <NewsContents>
                    <div className="newsDetail_naviBox">
                        <button type="button" onClick={()=>{navigate("/main")}} className="newsDetail_navi"><img src="/assets/images/basic/home.svg"/> Home</button>
                        <img src="/assets/images/basic/naviIcon.svg"/>
                        <button type="button" onClick={()=>{navigate("/news")}} className="newsDetail_navi">News</button>
                        <img src="/assets/images/basic/naviIcon.svg"/>
                        <p className="newsDetail_navi">{newsData?.category_name}</p>
                    </div>
                    <div className="newsDetail_titleBox">
                        <h1 className="newsDetail_title">{newsData?.title}</h1>
                        <div className="newsDetail_info">
                            <p className="newsDetail_cate">{newsData?.category_name}</p>
                            <span></span>
                            <p className="newsDetail_date">{setDateTime(newsData?.news_date,"yymmdd")}</p>
                        </div>
                    </div>    
                    <div className="newsDetail_contents">
                        <div className="newsDetail_text" dangerouslySetInnerHTML={{__html:newsData?.contents}}></div>
                        {newsData?.link ? <SectionBtn text='자세히 알아보기 <img src="/assets/images/basic/btn_icon.svg"/>' func={()=>{window.open(newsData?.link)}}/> : ""}
                    </div>
                    <div className="newsDetail_subListBox">
                        {newsPrevData ? <NewsDetailSubList type="prev" title={newsPrevData?.title} id={newsPrevData?.id} date={setDateTime(newsPrevData?.news_date,"yymmdd")} cate={newsPrevData?.category_name}/> : ""}
                        {newsNextData ? <NewsDetailSubList type="next" title={newsNextData?.title} id={newsNextData?.id} date={setDateTime(newsNextData?.news_date,"yymmdd")} cate={newsNextData?.category_name}/> : ""}
                    </div>
                </NewsContents>
                <BottomBtns/>
            </SubPage>
        </PageSizing>
        <Footer/>
        </>
    );
};

export default NewsDetail;