import Main from "pages/main/Main";
import About from "pages/sub/About";
import Careers from "pages/sub/Careers";
import NewsDetail from "pages/sub/NewsDetail";
import NewsList from "pages/sub/NewsList";
import React from "react";
import { Navigate } from "react-router-dom";

//로그인,비로그인 무관 페이지
export const publicRoutes = [
    {
        exact: true,
        path: "/",
        component: <Navigate to="/main" />
    },
    {
        exact: true,
        path: "/main",
        component: <Main/>,
    },
    {
        exact: true,
        path: "/about",
        component: <About/>,
    },
    {
        exact: true,
        path: "/news",
        component: <NewsList/>,
    },
    {
        exact: true,
        path: "/news/detail/:id",
        component: <NewsDetail/>,
    },
    {
        exact: true,
        path: "/careers",
        component: <Careers/>,
    },
];