import { PageSizing } from "component/basic/pageSizing";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SubPage } from "component/elements/items";
import { Footer } from "component/elements/footer";

const Careers = (props) => {
    const navigate = useNavigate();

    function resizeFrame(iframeObj){
        let myIframe = document.querySelectorAll(".careersIframe")[0];
        let innerBody = myIframe.contentWindow.document.body;
        let innerHeight = innerBody.scrollHeight + (innerBody.offsetHeight - innerBody.clientHeight);
        myIframe.style.height = innerHeight;
    }
    useEffect(() => {
        
    }, []);

    return (
        <>
        <PageSizing>
            <Header type="careers" bgType="sub"/>
            <SubPage>
                <iframe src="https://thespot.career.greetinghr.com" 
                // onLoad={()=>{resizeFrame()}} 
                frameBorder="0" className="careersIframe"/>
            </SubPage>
        </PageSizing>
        <Footer/>
        </>
    );
};

export default Careers;