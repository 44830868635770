import { SectionBtn } from "component/basic/btns";
import { Footer } from "./footer";
import { NewsBox, NewsItem } from "./news";
import useGet from "api/useGet";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { moveScroll, slideDown, slideUp } from "js/function";
import { Fragment } from "react";
import { useRef } from "react";

function MainTitle(data){
    return (
        <h1 className="mainTitle" dangerouslySetInnerHTML={{__html:data.title}}></h1>
    );
}

function TitleDot(data){
    return (
        <span className="titleDot"></span>
    );
}

//MainFooter
function MainFooter(data){
    const navigate = useNavigate();

    const nowDate = new Date();
    const newsApi = useGet({url:`/news`});
    const newsData = newsApi?.data?.news;

    const [newsEv,setNewsEv] =  useState("");
    const [newsBtnEv,setNewsBtnEv] =  useState("");
    const [listOpen,setListOpen] =  useState(false);

    function handleScroll(){
        let scrollTop = window.scrollY;
        let itemTop = document.querySelectorAll(".newsList_sub")[0].offsetTop;
        let discodeTop = document.querySelectorAll(".discode_section")[0].offsetTop;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight / 2;
        let windowH = window.innerHeight;

        if(scrollTop + headerSize >= itemTop - windowH * 0.5){
            setNewsEv("active")
        }

        if(scrollTop + windowH * 0.5 < itemTop || scrollTop + windowH >= discodeTop){
            setNewsBtnEv("")
            document.querySelectorAll(".bottomBtn_box")[0].classList.remove("bottomBtnUp")
        }else{
            setNewsBtnEv("btnActive")
            document.querySelectorAll(".bottomBtn_box")[0].classList.add("bottomBtnUp")
        }
    };

    useEffect(() => {
        handleScroll();
        const scrollEv = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(scrollEv);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
        <div className={`newsList_sub ${newsEv} ${newsBtnEv}`}>
            <div className="newsList_sub_titleBox">
                <MainTitle title="더스팟 최신 소식"/>
                <TitleDot/>
            </div>
            <div className={`newsList_subList ${listOpen ? "openListItem" : ""}`}>
                <NewsBox>
                    {newsData?.map((item, i) => (
                        <Fragment key={i}>{i <= 5 ? <NewsItem data={item}/> : ""}</Fragment>
                    ))}
                </NewsBox>
            </div>
            <button type="button" onClick={()=>{setListOpen(!listOpen)}} className="openList">{listOpen ? "접기" : "펼치기"}<img src="/assets/images/basic/main_down.svg"/></button>
            <div className="newsList_sub_btnBox">
                <SectionBtn text='더보기 <img src="/assets/images/basic/btn_icon.svg"/>' btnAddClass="setMoPage" func={()=>{navigate("/news")}}/>
            </div>
        </div>
        <div className="discode_section">
            <div className="discode_area">
                <img src="/assets/images/img/discode.svg"/>
                <h1 className="discode_title">지금 바로 <b>더스팟</b><br/><span><b>취약점 커뮤니티</b></span>에 참여하세요.</h1>
                <SectionBtn text='Discord 참가 <img src="/assets/images/basic/btn_icon.svg"/>' func={()=>{window.open("https://discord.gg/aQPxHuKDCx")}}/>
                <p className="discode_subText">모바일 디바이스에 합법적으로 액세스하기</p>
            </div>
        </div>
        <Footer/>
        </>
    );
}

function Role_itemBox(data){
    const navigate = useNavigate();

    return (
        <div className="role_itemBox">
            <img src={`/assets/images/img/main_icon_${data.type}.svg`}/>
            <h2 className="role_itemTitle">{
            data.type == 0 ? <>공공의<br/>안전에 기여</> : 
            data.type == 1 ? <>윤리적<br/>해킹을 지향</> : 
            data.type == 2 ? <>기업의<br/>컴플라이언스 처리</> : 
            data.type == 3 ? <>더스팟의<br/>또 다른 역할</> : ""}</h2>
            {data.type == 3 ? 
            <button type="button" onClick={()=>{navigate("/about")}} className="role_itemText btn_role_itemText">자세히 알아보기</button>
        :
            <p className="role_itemText">{
            data.type == 0 ? <>디지털 범죄로부터<br/>사회 안전망을 구축하고<br/>더 나아가 범죄 혐의점을 찾아<br/>정의를 실현합니다.</> : 
            data.type == 1 ? <>공공의 영역에서<br/>높은 윤리의식을 바탕으로<br/>범죄 조사를 위한 포렌식 기술을<br/>개발하고 제공합니다.</> : 
            data.type == 2 ? <>윤리경영에 필요한<br/>공정거래, 부패방지, 내부통제 등<br/>감사활동 전반을 기술로 지원합니다.</> : ""}</p>
        }
        </div>
    );
}

function DropMenu(data){
    const [openType,setOpenType] = useState(false);

    useEffect(() => {
        const slideItem = document.querySelectorAll(".dropMenu_list")[0];
        if(openType){
            slideDown(slideItem, 300, null);
        }else{
            slideUp(slideItem, 300);
        }
    }, [openType]);

    return (
        <div className="dropMenu">
            <button type="button" onClick={()=>{setOpenType(!openType)}} className={`btn_dropMenu ${openType ? "active" : ""}`}>
                {data.btnText}
                <img src="/assets/images/basic/dropMenu_icon.svg"/>
            </button>
            <div className={`dropMenu_list ${openType ? "active" : ""}`}>
                {data.listData && data.listData.map((item,i)=>(
                    <a href={item.link} key={i} target="_blank" className="dropMenu_linkItem">
                        {item.name}
                        <img src="/assets/images/basic/dropMenu_off.svg" className="dropMenu_icon dropMenu_icon_off"/>
                        <img src="/assets/images/basic/dropMenu_on.svg" className="dropMenu_icon dropMenu_icon_on"/>
                    </a>
                ))}
            </div>
        </div>
    );
}

function BottomBtns(data){
    const navigate = useNavigate();
    const copyItem = useRef(null);

    function copy(){
        copyItem.current.select();
        document.execCommand("copy");
        copyItem.current.blur();
    }

    return (
        <div className="bottomBtn_box" style={{display:`${data.blindType ? "none" : "block"}`}}>
            <button type="button" onClick={()=>{moveScroll("body",0)}} className="bottomBtn"><img src="/assets/images/basic/floating.svg"/></button>
            {data.cs ? 
            <>
                <a href="mailto:contact@thespot.kr" onClick={()=>{copy()}} className="bottomBtn"><img src="/assets/images/img/floating.svg"/></a>
                <input type="text" value="contact@thespot.kr" readOnly className="bottomBtn_copy" ref={copyItem}/>
            </>
            :""}
        </div>
    );
}

function AboutItem0(data){
    return (
        <div className="aboutSection_0_item">
            <div className="aboutSection_0_img" style={{backgroundImage:`url('${data.img}')`}}></div>
            <h1 className="aboutSection_0_text" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

function AboutItem1(data){
    return (
        <div className="aboutSection_1_item">
            <div className="aboutSection_1_img" style={{backgroundImage:`url('${data.img}')`,paddingBottom:`${data.size}%`}}></div>
            <h2 className="aboutSection_1_text" dangerouslySetInnerHTML={{__html:data.text}}></h2>
        </div>
    );
}

function AboutItem2(data){
    return (
        <div className="aboutSection_2_item">
            <div className="aboutSection_2_img" style={{backgroundImage:`url('${data.img}')`,paddingBottom:`${data.size}%`}}></div>
            <div className="aboutSection_2_textBox">
                {data.children}
            </div>
        </div>
    );
}

function AboutItem2Text(data){
    return (
        <p className="aboutSection_2_text"><img src="/assets/images/basic/chk_icon.svg"/>{data.text}</p>
    );
}

function AboutItem3(data){
    return (
        <div className="aboutSection_3_item">
            <p className="aboutSection_3_date" dangerouslySetInnerHTML={{__html:data.date}}></p>
            <p className="aboutSection_3_text" dangerouslySetInnerHTML={{__html:data.text}}></p>
        </div>
    );
}

function AboutItem4(data){
    return (
        <div className="aboutSection_4_item">
            <img src={data.img}/>
            <div className="aboutSection_4_textBox">
                <h2 className="aboutSection_4_name" dangerouslySetInnerHTML={{__html:data.name}}></h2>
                <p className="aboutSection_4_text" dangerouslySetInnerHTML={{__html:data.text}}></p>
            </div>
        </div>
    );
}

function SubPage(data){
    return (
        <div className="subPage">
            {data.children}
        </div>
    );
}

export {MainTitle, TitleDot, MainFooter, Role_itemBox, BottomBtns, AboutItem0, AboutItem1, AboutItem2 ,AboutItem2Text, AboutItem4, AboutItem3, SubPage, DropMenu}