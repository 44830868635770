import { PageSizing } from "component/basic/pageSizing";
import { Header } from "component/elements/header";
import { slideDown, slideToggle, slideUp } from "js/function";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomBtns, MainTitle, SubPage, TitleDot } from "component/elements/items";
import useGet from "api/useGet";
import { NewsBox, NewsContents, NewsItem, NewsSlide } from "component/elements/news";
import { CategoryBox } from "component/basic/category";
import PageNation from "component/basic/pageNation";
import { Footer } from "component/elements/footer";

const NewsList = (props) => {
    const navigate = useNavigate();
    const [year,setYear] =  useState("");
    const [category,setCategory] =  useState("");
    const [page,setPage] = useState(1);

    const [yearOpen,setYearOpen] =  useState(false);

    const newsApi = useGet({url:`/news?year=${year}&category_id=${category}&is_paging=1&page=${page}`});
    const newsData = newsApi?.data?.news;

    const newsCateApi = useGet({url:`/news/category`});
    const newsCateData = newsCateApi?.data?.news_categories;

    const newsYearApi = useGet({url:`/news/year`});
    const newsYearData = newsYearApi?.data?.years;

    useEffect(() => {
        const slideItem = document.querySelectorAll(".m_yearList")[0];
        if(yearOpen){
            slideDown(slideItem, 300, null);
        }else{
            slideUp(slideItem, 300);
        }
    }, [yearOpen]);

    return (
        <>
        <PageSizing>
            <Header type="news" bgType="sub"/>
            <SubPage>
                <NewsSlide/>
                <NewsContents>
                    <div className="newsList_titleBox">
                        <MainTitle title="Spot News"/>
                        <TitleDot/>
                    </div>
                    <div className="listCategory_section">
                        <CategoryBox data={newsCateData} keyName={"category_name"} val={category} func={(val)=>{setCategory(val)}} addClass="listCategory" allChk={{id:"",name:"전체"}}/>
                        <CategoryBox data={newsYearData} keyName={"year"} val={year} func={(val)=>{setYear(val)}} addClass="listYear notBg" dataType="name" allChk={{id:"",name:"전체"}}/>
                        <div className="m_year">
                            <button type="button" onClick={()=>{setYearOpen(!yearOpen)}} className={`btn_yearOpen ${yearOpen ? "active":""}`}></button>
                            <div className="m_yearList">
                                <button type="button" onClick={()=>{setYear("");setYearOpen(false)}} className={`btn_m_yearCh ${year == "" ? "active" : ""}`}>전체</button>
                                {
                                    newsYearData && newsYearData.map((item,i)=>(
                                        <button type="button" key={i} onClick={()=>{setYear(item.year);setYearOpen(false)}} className={`btn_m_yearCh ${year == item.year ? "active" : ""}`}>{item.year}</button>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="list_section">
                        <NewsBox>
                        {
                            newsData && newsData?.data.length > 0 ?
                                <>{newsData?.data?.map((item, i) => (
                                    <NewsItem key={i} data={item}/>
                                ))}</>
                            :
                            <p className="notList">등록된 뉴스가 없습니다.</p>
                        }
                        </NewsBox>
                    </div>
                    <PageNation perPage={5} total={newsData?.last_page} page={page} func={(i)=>{setPage(i);window.scrollTo(0,0)}}/>
                </NewsContents>
                <BottomBtns cs={true}/>
            </SubPage>
        </PageSizing>
        <Footer/>
        </>
    );
};

export default NewsList;