import { PageSizing } from "component/basic/pageSizing";
import { Header } from "component/elements/header";
import { moveScroll } from "js/function";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectCoverflow } from "swiper/modules";
import { AboutItem0, AboutItem1, AboutItem2, AboutItem2Text, AboutItem3, AboutItem4, BottomBtns, MainFooter, MainTitle, TitleDot } from "component/elements/items";
import { FullBanner } from "component/elements/fullBanner";

const About = (props) => {
    const navigate = useNavigate();
    const [bgType,setBgType] =  useState("");
    const [colorType,setColorType] =  useState("");
    const [bannerType,setBannerType] =  useState("");

    const [sectionType0,setSectionType0] =  useState("");
    const [sectionType1,setSectionType1] =  useState("");
    const [sectionType4,setSectionType4] =  useState("");

    const [historyType,setHistoryType] =  useState("2023");
    
    const [blindType,setBlindType] =  useState(true);

    const historyData = {
        "2023":[
            {
                date:"2023.09",
                text:"스틸리언 사이버보안 인재 멘토링 프로그램(SSL 4기) 공동 운영"
            },{
                date:"2023.07",
                text:"International Police Expo(인도 국제 경찰 박람회) 참가"
            },{
                date:"2023.06",
                text:"국가기관 정보보호 연구 과제 선정"
            },{
                date:"2023.04",
                text:"글로벌 스마트폰 시리즈(Xiaomi, Vivo, Realme, Micromax, Tecno, Lava) 암호화 기술이 적용된 스마트폰 전체 데이터 수집 성공"
            },{
                date:"2023.03",
                text:"Android 13 기반 암호화 기술이 적용된 스마트폰의 전체 데이터 수집 성공"
            },{
                date:"2023.01",
                text:"MTK 디바이스에 대한 Full File System 수집 기술 개발"
            }
        ],
        "2022":[
            {
                date:"2022.09",
                text:"더스팟-GMDSOFT-크레센에쿼티파트너스 인수 업무 협약"
            },{
                date:"2022.06",
                text:"고려대 정보보호대학원과 디지털 포렌식 공동연구센터 설립을 위한 MOU 체결"
            },{
                date:"2022.04",
                text:"세계 최초 갤럭시 S22 시리즈 암호화 기술이 적용된 스마트폰 전체 데이터 수집 성공"
            },{
                date:"2022.03",
                text:"신용보증기금 투자옵션부보증 기업 선정"
            },{
                date:"2022.02",
                text:"대구SW마이스터고등학교 산학협력 MOU 체결"
            }
        ],
        "2021":[
            {
                date:"2021.12",
                text:"국내특허출원 ‘복호화 정보에 따른 유저 데이터 수집 방법’<br/>국내특허출원 ‘복호화 데이터 범위에 따른 유저 데이터 수집 방법’"
            },{
                date:"2021.10",
                text:"삼성 보안 폴더(KNOX Secure Storage), 듀얼 메신저 해독 수집 기술 개발<br/>세계 최초 Android 12 기반의 FBE 암호화 기술이 적용된 스마트폰 전체 데이터 수집 성공"
            },{
                date:"2021.09",
                text:"기업부설연구소 설립, 병역특례업체 선정 (산업기능요원)<br/>서울기술연구원 캠퍼스타운 R&D 사업 선정(더스팟-고려대학교 컨소시엄)"
            },{
                date:"2021.08",
                text:"TCB 기술신용평가 T-3 인증 (기술특례상장급, 나이스디앤비)"
            },{
                date:"2021.06",
                text:"고려대학교 특허 기술이전 ‘악성 어플리케이션 또는 악성 웹사이트 탐지 방법 및 시스템’<br/>홍릉강소특구 기술이전형 R&BD 사업 선정(더스팟-고려대학교 컨소시엄)"
            },{
                date:"2021.05",
                text:"과학기술정보통신부 정보보호스타트업 해외진출 육성기업 선정(KISA, KISIA, 빅뱅앤젤스 주관)"
            }
        ],
        "2020":[
            {
                date:"2020.12",
                text:"고려대학교 안암동 캠퍼스타운 창업경진대회 대상"
            },{
                date:"2020.11",
                text:"고려대학교 크림슨창업지원단 BI(창업보육센터) 입주<br/>서울핀테크랩 입주기업 선정"
            },{
                date:"2020.03",
                text:"세계 최초 Android 10 rlqksdml 암호화 기술이 적용된 스마트폰 전체 데이터 수집 성공"
            },{
                date:"2020.02",
                text:"국내특허등록 ‘스마트기기에 대한 데이터 복구 방법’<br/>국내특허등록 ‘모바일 디바이스에 대한 디지털 포렌식 방법’<br/>창업진흥원 초기창업패키지 선정<br/>기술지주회사 자회사 R&BD 사업 선정"
            },{
                date:"2020.01",
                text:"국내상표등록 ‘디지털 포렌식 브랜드, Redot’"
            }
        ],
        "2019":[
            {
                date:"2019.12",
                text:"기술보증기금 벤처기업 인증<br/>강원지방중소벤처기업청실사구시 창업경진대회 최우수상"
            },{
                date:"2019.09",
                text:"강원SW페스티벌 대상"
            },{
                date:"2019.08",
                text:"교육부 학생 창업유망팀 300 인증"
            },{
                date:"2019.06",
                text:"강원대학교 캡스톤디자인 경진대회 대상"
            },{
                date:"2019.01",
                text:"국내상표출원 ‘디지털 포렌식 브랜드, Redot’"
            }
        ],
        "2018":[
            {
                date:"2018.11",
                text:"주식회사 더스팟 법인 설립"
            },{
                date:"2018.09",
                text:"태국국가연구위원회 특별상 ‘암호화된 기기에서 데이터 수집 및 무결성 보장 기술’<br/>중국발명전시회 은상 ‘암호화된 기기에서 데이터 수집 및 무결성 보장 기술’<br/>제1회 헬로 스타트업 공모전 최우수상 (강원창조경제혁신센터)<br/>창업진흥원 기술혁신형 창업기업 지원사업 선정 (현 예비창업패키지)"
            },{
                date:"2018.08",
                text:"국내특허출원 ‘스마트기기에 대한 데이터 복구 방법’<br/>국내특허출원 ‘모바일 디바이스에 대한 디지털 포렌식 방법’"
            }
        ]
    }

    function handleScroll(){
        let scrollTop = window.scrollY;
        let contentsTop = document.querySelectorAll(".about_contents")[0].offsetTop;
        let headerSize = document.querySelectorAll(".header")[0].clientHeight / 2;

        let scction_0 = document.querySelectorAll(".aboutSection_0")[0].offsetTop;
        let scction_1 = document.querySelectorAll(".aboutSection_1")[0].offsetTop;
        let scction_4 = document.querySelectorAll(".aboutSection_4")[0].offsetTop;

        let windowH = window.innerHeight;

        document.querySelectorAll(".aboutBanner_0_textBox")[0].style.transform = `translateY(-${scrollTop * 0.02 >= 20 ? 20 : scrollTop * 0.02 <= 0 ? 0 : scrollTop * 0.02}px)`;
        document.querySelectorAll(".aboutBanner_0_textBox")[0].style.opacity = 1 - scrollTop/1000 >= 1 ? 1 : 1 - scrollTop/1000 <= 0 ? 0 : 1 - scrollTop/1000;

        document.querySelectorAll(".aboutBanner_1_textBox")[0].style.transform = `translateY(${(scrollTop - 1500) * 0.02 >= 20 ? 20 : (scrollTop - 1500) * 0.02 <= 0 ? 0 : (scrollTop - 1500) * 0.02}px)`;
        document.querySelectorAll(".aboutBanner_1_textBox")[0].style.opacity = (scrollTop - 1500) / 1000 >= 1 ? 1 : (scrollTop - 1500) / 1000 <= 0 ? 0 : (scrollTop - 1500) / 1000;

        if(scrollTop + headerSize >= contentsTop){
            setColorType("")
            setBgType("sub")
        }else{
            setColorType("wColor")
            setBgType("")
        }

        if(scrollTop + headerSize >= contentsTop - windowH * 0.5){
            setBlindType(false)
        }else{
            setBlindType(true)
        }

        if(scrollTop + headerSize >= scction_0 + contentsTop - windowH * 0.5){
            setSectionType0("active")
        }

        if(scrollTop + headerSize >= scction_1 + contentsTop - windowH * 0.5){
            setSectionType1("active")
        }

        if(scrollTop + headerSize >= scction_4 + contentsTop - windowH * 0.5){
            setSectionType4("active")
        }
    };

    useEffect(() => {
        handleScroll();
        const scrollEv = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(scrollEv);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <PageSizing addClass="mainPage">
            <Header type="about" colorType={colorType} bgType={bgType}/>
            <FullBanner height="3500">
                <div className="about_bg">
                    <video autoPlay muted loop playsInline>
                        <source src="/assets/images/img/about_bg.mp4" type="video/mp4" />
                    </video>
                </div>
                <div className="aboutBanner aboutBanner_0">
                    <div className="aboutBanner_0_textBox">
                        <p className="aboutBanner_caption">Want to Be</p>
                        <h1 className="aboutBanner_title">디지털 세상이<br/>ZERO 챌린지가 되는 그날까지</h1>
                    </div>
                </div>
                <div className="aboutBanner aboutBanner_1">
                    <div className="aboutBanner_1_textBox">
                        <p className="aboutBanner_caption">우리의 가능성</p>
                        <h2 className="aboutBanner_text">2015년, 미 연방수사국(FBI)은 테러리스트의 스마트폰 잠금을 해제하지 못해 수사지연이 발생하였습니다. <br/><br/>
                        해당 사건을 기점으로 법의학 제품은 시장 전환기를 맞이하게 되었고,<br/>디지털 범죄 대응을 위해선 더 높은 수준의 기술력이 필요하게 됩니다.<br/><br/>
                        더스팟은 Full Disk Encryption이 적용되었던 2016년부터<br/>독보적인 기술력으로 모바일 시스템에 접근해 액세스 할 수 있는 기술을 확보하였고<br/>
                        매 해 급변하는 오펜시브 시큐리티 시장에서 모바일 분야 Top-Tier가 되기위한 Zero-Day 연구를 지속하고 있습니다. <br/><br/>
                        </h2>
                    </div>
                </div>
                <div className="btn_bannerBottomItem" onClick={()=>{moveScroll(".about_contents",0)}}><img src="/assets/images/basic/main_down.svg"/></div>
            </FullBanner>
            <div className="about_contents">
                <div className={`aboutSection_0 ${sectionType0}`}>
                    <div className="aboutSection_titleBox aboutSection_titleFlexBox">
                        <div className="aboutSection_title">
                            <MainTitle title="시장의 Pain Point"/>
                            <TitleDot/>
                        </div>
                        <p className="aboutSection_titleCaption"></p>
                    </div>
                    <div className="aboutSection_0_list">
                        <AboutItem0 text="OS 제조사의 강화된 보안정책으로<br/>사용자 데이터 암호화가 적용" img="/assets/images/img/about_img_0.svg"/>
                        <AboutItem0 text="암호화된 데이터는 증거로 활용이<br/>어려워지며 수사 지연이 발생" img="/assets/images/img/about_img_1.svg"/>
                        <AboutItem0 text="정밀 포렌식을 하기 위해선<br/>높은 기술 사용료가 필요" img="/assets/images/img/about_img_2.svg"/>
                    </div>
                    <div className="aboutSection_0_slideBox">
                        <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            loopedSlides={1}
                            modules={[Pagination,Autoplay]}
                            autoplay={{delay: 5000,disableOnInteraction:false}}
                            loop={true}
                            pagination={{
                                el: '.custom_pagination_0'
                            }}
                        >
                            <SwiperSlide>
                                <AboutItem0 text="OS 제조사의 강화된 보안정책으로<br/>사용자 데이터 암호화가 적용" img="/assets/images/img/about_img_0.svg"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem0 text="암호화된 데이터는 증거로 활용이<br/>어려워지며 수사 지연이 발생" img="/assets/images/img/about_img_1.svg"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem0 text="정밀 포렌식을 하기 위해선<br/>높은 기술 사용료가 필요" img="/assets/images/img/about_img_2.svg"/>
                            </SwiperSlide>
                            <div className="custom_pagination custom_pagination_0"></div>
                        </Swiper>
                    </div>
                </div>
                <div className={`aboutSection_1 ${sectionType1}`}>
                    <div className="aboutSection_titleBox">
                        <MainTitle title="연구 분야"/>
                        <TitleDot/>
                    </div>
                    <div className="aboutSection_1_list">
                        <div className="aboutSection_1_listArea">
                            <AboutItem1 text="Qualcomm, Samsung Exynos, MTK, UNISOC, HiSilicon, Spreadtrum 등 다양한 칩셋기반 연구 수행" img="/assets/images/img/about_img_1_0.png" size="58.8"/>
                            <AboutItem1 text="Reverse Engineering, Assembly language, C/C++ 등 취약점 연구를 위한 필수 기술 취급" img="/assets/images/img/about_img_1_1.png" size="101.3"/>
                            <AboutItem1 text="Bootloader, Kernel, OS 등 각종 보안 기능 분석" img="/assets/images/img/about_img_1_2.png" size="126.8"/>
                        </div>
                        <div className="aboutSection_1_listArea">
                            <AboutItem1 text="System virtualization / Fuzzing<br/><span>(Simulator, 실제 기기)</span>" img="/assets/images/img/about_img_1_3.png" size="150.2"/>
                            <AboutItem1 text="OS 취약성 연구<br/><span>(모바일 OS 중심)</span>" img="/assets/images/img/about_img_1_4.png" size="62.3"/>
                        </div>
                    </div>
                    <div className="aboutSection_1_slideBox">
                        <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            loopedSlides={2}
                            modules={[Pagination,Autoplay]}
                            autoplay={{delay: 5000,disableOnInteraction:false}}
                            loop={true}
                            pagination={{
                                el: '.custom_pagination_1'
                            }}
                        >
                            <SwiperSlide>
                                <AboutItem1 text="Qualcomm, Samsung Exynos, MTK, UNISOC, HiSilicon, Spreadtrum 등 다양한 칩셋기반 연구 수행" img="/assets/images/img/about_img_1_0_m.png" size="106.6"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem1 text="Reverse Engineering, Assembly language, C/C++ 등 취약점 연구를 위한 필수 기술 취급" img="/assets/images/img/about_img_1_1_m.png" size="106.6"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem1 text="Bootloader, Kernel, OS 등 각종 보안 기능 분석" img="/assets/images/img/about_img_1_2_m.png" size="106.6"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem1 text="System virtualization / Fuzzing<br/><span>(Simulator, 실제 기기)</span>" img="/assets/images/img/about_img_1_3_m.png" size="106.6"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem1 text="OS 취약성 연구<br/><span>(모바일 OS 중심)</span>" img="/assets/images/img/about_img_1_4_m.png" size="106.6"/>
                            </SwiperSlide>
                            <div className="custom_pagination custom_pagination_1"></div>
                        </Swiper>
                    </div>
                </div>
                <div className="aboutSection_2">
                    <div className="aboutSection_titleBox">
                        <MainTitle title="연구 히스토리"/>
                        <TitleDot/>
                    </div>
                    <div className="aboutSection_2_slideBox">
                        <Swiper
                            effect={"coverflow"}
                            spaceBetween={16}
                            slidesPerView={1}
                            loopedSlides={2}
                            modules={[EffectCoverflow,Pagination,Autoplay]}
                            centeredSlides={true}
                            slideToClickedSlide={true}
                            coverflowEffect={{
                                rotate: -2,
                                stretch: 160,
                                slideShadows: false,
                            }}
                            autoplay={{delay: 5000,disableOnInteraction:false}}
                            loop={true}
                            pagination={{
                                el: '.custom_pagination_2'
                            }}
                        >
                            <SwiperSlide>
                                <AboutItem2 img="/assets/images/img/about_img_2_0.png">
                                    <h2 className="aboutSection_2_itemName">모바일 데이터 해독 및 수집 기술</h2>
                                    <AboutItem2Text text="암호화된 스마트폰의 모든 데이터를 해독하여 PC, 외부 저장장치로 복제하는 기술"/>
                                    <AboutItem2Text text="상기 보안 기술과 시스템에 영향을 미치지 않으면서 무결하게 데이터 복호화 및 복제 수행"/>
                                    <AboutItem2Text text="타사에서 불가능한 시스템 권한을 획득하여 시스템 내 모든 영역에 액세스"/>
                                    <AboutItem2Text text="삼성 갤럭시에 적용된 KNOX Security Folder, Dual Messenger 저장소에 액세스하여 전체 데이터 수집 가능"/>
                                </AboutItem2>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem2 img="/assets/images/img/about_img_2_1.png">
                                    <h2 className="aboutSection_2_itemName">안드로이드 OS 잠금 해제 기술</h2>
                                    <AboutItem2Text text="PIN, 패턴 등 사용자 암호로 잠겨 있는 스마트폰의 잠금 해제 기술"/>
                                    <AboutItem2Text text="BFU(Before Unlock), AFU(After Unlock) Storage 수집 지원"/>
                                    <AboutItem2Text text="Secure Startup이 적용되지 않은 일부 Full Disk Encryption, Non-Encrypted 기종 잠금화면 해제"/>
                                    <AboutItem2Text text="Secure Startup이 적용된 일부 Full Disk Encryption, File Based Encryption 기종 잠금화면 해제"/>
                                </AboutItem2>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem2 img="/assets/images/img/about_img_2_2.png">
                                    <h2 className="aboutSection_2_itemName">삭제된 데이터 복구 기술</h2>
                                    <AboutItem2Text text="삭제된 문서, 사진, 문자, 통화기록, SNS, 메신저(카카오톡 등)을 복구하여 분석 가능 범위 확장"/>
                                    <AboutItem2Text text="파일 시스템 비할당영역 데이터 복구 기술 보유(EXT4 Carving 기술)"/>
                                    <AboutItem2Text text="데이터베이스 비할당영역 데이터 복구 기술 보유(SQLite DB Carving 기술)"/>
                                    <AboutItem2Text text="Relem DB 데이터 복구 기술 고려대학교 정보보호대학원과 공동 연구"/>
                                </AboutItem2>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem2 img="/assets/images/img/about_img_2_3.png">
                                    <h2 className="aboutSection_2_itemName">암호화된 어플리케이션 해독 기술</h2>
                                    <AboutItem2Text text="데이터를 암호화하여 저장하는 메신저, SNS 어플리케이션의 메세지 복호화"/>
                                    <AboutItem2Text text="국내·외 다수 메신저 데이터 해독기술 보유(카카오톡, 라인, WeChat, Telegram, Facebook, Instagram 등)"/>
                                </AboutItem2>
                            </SwiperSlide>
                            <div className="custom_pagination custom_pagination_2"></div>
                        </Swiper>
                    </div>
                    <div className="aboutSection_2_slideBox_m">
                        <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            loopedSlides={1}
                            modules={[Pagination,Autoplay]}
                            autoplay={{delay: 5000,disableOnInteraction:false}}
                            loop={true}
                            pagination={{
                                el: '.custom_pagination_2_1'
                            }}
                        >
                            <SwiperSlide>
                                <AboutItem2 img="/assets/images/img/about_img_2_0.png">
                                    <h2 className="aboutSection_2_itemName">모바일 데이터 해독 및 수집 기술</h2>
                                    <AboutItem2Text text="암호화된 스마트폰의 모든 데이터를 해독하여 PC, 외부 저장장치로 복제하는 기술"/>
                                    <AboutItem2Text text="상기 보안 기술과 시스템에 영향을 미치지 않으면서 무결하게 데이터 복호화 및 복제 수행"/>
                                    <AboutItem2Text text="타사에서 불가능한 시스템 권한을 획득하여 시스템 내 모든 영역에 액세스"/>
                                    <AboutItem2Text text="삼성 갤럭시에 적용된 KNOX Security Folder, Dual Messenger 저장소에 액세스하여 전체 데이터 수집 가능"/>
                                </AboutItem2>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem2 img="/assets/images/img/about_img_2_1.png">
                                    <h2 className="aboutSection_2_itemName">안드로이드 OS 잠금 해제 기술</h2>
                                    <AboutItem2Text text="PIN, 패턴 등 사용자 암호로 잠겨 있는 스마트폰의 잠금 해제 기술"/>
                                    <AboutItem2Text text="BFU(Before Unlock), AFU(After Unlock) Storage 수집 지원"/>
                                    <AboutItem2Text text="Secure Startup이 적용되지 않은 일부 Full Disk Encryption, Non-Encrypted 기종 잠금화면 해제"/>
                                    <AboutItem2Text text="Secure Startup이 적용된 일부 Full Disk Encryption, File Based Encryption 기종 잠금화면 해제"/>
                                </AboutItem2>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem2 img="/assets/images/img/about_img_2_2.png">
                                    <h2 className="aboutSection_2_itemName">삭제된 데이터 복구 기술</h2>
                                    <AboutItem2Text text="삭제된 문서, 사진, 문자, 통화기록, SNS, 메신저(카카오톡 등)을 복구하여 분석 가능 범위 확장"/>
                                    <AboutItem2Text text="파일 시스템 비할당영역 데이터 복구 기술 보유(EXT4 Carving 기술)"/>
                                    <AboutItem2Text text="데이터베이스 비할당영역 데이터 복구 기술 보유(SQLite DB Carving 기술)"/>
                                    <AboutItem2Text text="Relem DB 데이터 복구 기술 고려대학교 정보보호대학원과 공동 연구"/>
                                </AboutItem2>
                            </SwiperSlide>
                            <SwiperSlide>
                                <AboutItem2 img="/assets/images/img/about_img_2_3.png">
                                    <h2 className="aboutSection_2_itemName">암호화된 어플리케이션 해독 기술</h2>
                                    <AboutItem2Text text="데이터를 암호화하여 저장하는 메신저, SNS 어플리케이션의 메세지 복호화"/>
                                    <AboutItem2Text text="국내·외 다수 메신저 데이터 해독기술 보유(카카오톡, 라인, WeChat, Telegram, Facebook, Instagram 등)"/>
                                </AboutItem2>
                            </SwiperSlide>
                            <div className="custom_pagination custom_pagination_2_1"></div>
                        </Swiper>
                    </div>
                </div>
                <div className="aboutSection_3">
                    <div className="aboutSection_titleBox">
                        <MainTitle title="우리의 발자취"/>
                        <TitleDot/>
                    </div>
                    <div className="aboutSection_3_list">
                        <div className="aboutSection_3_tapBox">
                            <button type="button" onClick={()=>{setHistoryType("2023")}} className={`aboutSection_3_tap ${historyType == "2023" ? "active" : ""}`}>2023</button>
                            <button type="button" onClick={()=>{setHistoryType("2022")}} className={`aboutSection_3_tap ${historyType == "2022" ? "active" : ""}`}>2022</button>
                            <button type="button" onClick={()=>{setHistoryType("2021")}} className={`aboutSection_3_tap ${historyType == "2021" ? "active" : ""}`}>2021</button>
                            <button type="button" onClick={()=>{setHistoryType("2020")}} className={`aboutSection_3_tap ${historyType == "2020" ? "active" : ""}`}>2020</button>
                            <button type="button" onClick={()=>{setHistoryType("2019")}} className={`aboutSection_3_tap ${historyType == "2019" ? "active" : ""}`}>2019</button>
                            <button type="button" onClick={()=>{setHistoryType("2018")}} className={`aboutSection_3_tap ${historyType == "2018" ? "active" : ""}`}>2018</button>
                        </div>
                        <div className="aboutSection_3_listBox">
                            {historyData[historyType]?.map((item, i) => (
                                <AboutItem3 key={i} date={item.date} text={item.text}/>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={`aboutSection_4 ${sectionType4}`}>
                    <div className="aboutSection_titleBox">
                        <MainTitle title="더스팟 조직 문화"/>
                        <TitleDot/>
                    </div>
                    <div className="aboutSection_4_list">
                        <AboutItem4 img="/assets/images/img/about_icon_0.svg" name="전사 워크샵" text="매 년 특정 분기에 전사 제주도 워크샵 진행"/>
                        <AboutItem4 img="/assets/images/img/about_icon_1.svg" name="성장 및 역량 강화 지원" text="직 관련 기술서적 구입비 및 교육비 무제한 지원<br/>구성원들에게 구하는 전문적인 Feedback & Advising"/>
                        <AboutItem4 img="/assets/images/img/about_icon_2.svg" name="카페&스낵바" text="illy 캡슐 커피를 포함한 음료 무한 제공<br/>사내 스낵바 상시 운영"/>
                        <AboutItem4 img="/assets/images/img/about_icon_3.svg" name="기본 복지제도" text="중식 식대지원<br/>경조사 지원<br/>임직원 대상 종합건강검진 제공<br/>청년내일채움공제<br/>중소기업소득세감면"/>
                        <AboutItem4 img="/assets/images/img/about_icon_4.svg" name="생일 축하 문화" text="생일 당일 생일자에게 축하 상품 지급<br/>생일 당일 묻지도 따지지도 않는 Free 반차"/>
                    </div>
                </div>
            </div>
            <MainFooter/>
            <BottomBtns blindType={blindType}/>
        </PageSizing>
    );
};

export default About;